import ContactDemo from "../../components/contactDemo"
// Import Components
import Layout from "../../components/layout"
import React from "react"
// Video Player
import ReactPlayer from "react-player"
import SEO from "../../components/seo"
// Import Images
import ToastRooamLogos from "../../images/demo-brands/toast-rooam-logos.jpg"

const Toast = () => {
  return (
    <Layout>
      <SEO
        title="Rooam + Toast - Rooam is Toast’s first mobile payments partner"
        description="Providing a secure payment experience for guests that’s seamlessly integrated into Toast POS for operators."
      />
      <div className="top-message text-center background-dark-grey">
        <p className="no-top-bottom small font-weight-400 text-light-grey">
          Accept Apple Pay &amp; Google Pay today!
        </p>
      </div>
      <div className="section no-bottom">
        <div className="container medium text-center">
          <div className="peak-wrap no-top">
            <div className="icon rooam-toast-logos">
              <img src={ToastRooamLogos} alt="Toast + Rooam Logos" />
            </div>
          </div>
          <p className="lead text-light-grey no-bottom">
            Rooam is Toast’s first mobile payments partner, combining two best
            in class technology companies to provide a secure payment experience
            for guests that’s seamlessly integrated into Toast POS for
            operators.
          </p>
        </div>
      </div>

      <div className="section no-top-bottom">
        <ContactDemo formName="request-demo-toast" />
      </div>

      <div className="section">
        <div className="container">
          <div className="player-wrapper video-rounded video-border no-select">
            <ReactPlayer
              url="https://media.rooam.co/video/b2b/Rooam-Toast-Flow.mp4"
              className="react-player video-rounded"
              playing
              width="100%"
              height="100%"
              muted={true}
              controls={true}
              light="../../videos/posters/Rooam-App.jpg"
              playIcon={
                <div className="play-btn">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-icon"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 22v-20l18 10-18 10z" />
                    </svg>
                  </span>
                </div>
              }
            />
          </div>
        </div>
        <div className="container">
          <p className="small text-grey text-center">
            Watch an overview of how Rooam works with Toast
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Toast
